<template>
  <div>
    <b-row>
      <b-col v-for="socialField in socialInputs" :key="socialField.dataField" cols="12" md="6" lg="4">
        <b-form-group :label="socialField.label" :label-for="socialField.dataField">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon size="16" :icon="socialField.icon"/>
            </b-input-group-prepend>
            <b-form-input v-model="formData[socialField.dataField]" type="url" maxlength="15"/>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" xl="2">
        <b-button variant="primary" block @click="saveUpdateChanges()">
          Guardar Colegio
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    modeView: {
      required: true,
    },
  },  
  data() {
    return {
      socialInputs: [
        {
          icon: 'TwitterIcon',
          dataField: 'twitter',
          label: 'Twitter',
        },
        {
          icon: 'FacebookIcon',
          dataField: 'facebook',
          label: 'facebook',
        },
        {
          icon: 'InstagramIcon',
          dataField: 'instagram',
          label: 'instagram',
        }
      ]
    }
  },  
  methods: {
    async saveUpdateChanges() {
      if(this.modeView) {
        this.update()
      } else {
        this.create()
      }
    },
    async create() {
      await this.$store.dispatch('schools/store', this.formData)
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se guardo el Colegio exitosamente')
        this.$router.push({ name: 'schools' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async update() {
      await this.$store.dispatch('schools/update', {data: this.formData, id: this.modeView})
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se guardo el Colegio exitosamente')
        this.$router.push({ name: 'schools' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    message(icon, variant, text) {
      this.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Respuesta',
        icon: icon,
        variant: variant,
        text: text,
      }})
    }
  }, 
}
</script>