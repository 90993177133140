<template>
  <div>
     <validation-observer ref="directionsForm">
      <b-row>
        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Teléfono">
            <validation-provider
              #default="{ errors }"
              name="telefono"
              rules="required"
              >

              <vue-tel-input
               @keypress="filterNumber" 
               v-model="formData.phone" 
               maxLength="15"
               :defaultCountry="'VE'"
               />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Correo electrónico">
            <validation-provider
              #default="{ errors }"
              name="correo"
              rules="required|email">
              <b-form-input type="email" v-model="formData.email" maxLength="40"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Correo electrónico Alternativo">
            <validation-provider
              #default="{ errors }"
              name="correo alternativo"
              rules="email">
              <b-form-input type="email" v-model="formData.email2" maxLength="40"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Teléfono Alternativo">
            <vue-tel-input @keypress="filterNumber" v-model="formData.phone2" maxLength="15" :defaultCountry="'VE'"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" xl="2">
          <b-button variant="primary" block @click="next()">
            Siguiente
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
localize('es', es)

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, ValidationProvider, ValidationObserver
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email
    }
  },
  methods: {
    filterNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if 
        (keyCode < 48 || keyCode > 57)
     {
        event.preventDefault(); 
      }
    },
    async next() {
      if (!await this.$refs.directionsForm.validate()) {
        return
      } 
      this.$emit('next-step')
    },
  }, 
}
</script>