<template>
  <validation-observer ref="personaContactForm">
    <b-row>
      <b-col md="6" xl="4" class="mb-1">
        <b-form-group label="Nombre de contacto">
          <validation-provider
            #default="{ errors }"
            name="nombre de contacto"
            rules="required">
            <b-form-input v-model="formData.contact_name" maxLength="30"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="6" xl="4" class="mb-1">
        <b-form-group label="Correo de contacto">
          <validation-provider
            #default="{ errors }"
            name="correo de contacto"
            rules="required|email">
            <b-form-input v-model="formData.contact_email" maxLength="40"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="6" xl="4" class="mb-1">
        <b-form-group label="Telefono de contacto">
          <validation-provider
            #default="{ errors }"
            name="telefono de contacto"
            rules="required">
            <vue-tel-input 
            @keypress="filterNumber" 
            v-model="formData.contact_phone" 
            maxLength="15" 
            :defaultCountry="'VE'"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
   <b-row>
      <b-col md="2" xl="2">
        <b-button variant="primary" block @click="next()">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
localize('es', es)

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, ValidationProvider, ValidationObserver,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email
    }
  },
  methods: {
    filterNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if 
        (keyCode < 48 || keyCode > 57)
     {
        event.preventDefault(); 
      }
    },
    async next() {
      if (!await this.$refs.personaContactForm.validate()) {
        return
      } 
      this.$emit('next-step')
    },
  }, 
}
</script>